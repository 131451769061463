import React, { Component } from 'react';
import LeadsTable from './LeadsTable'

class Leads extends Component{
  render() {
  	const { params } = this.props.match
    return (
        <LeadsTable campaignId={params.campaignId} />
    );
  }
}

export default Leads;
