import React, { Component } from 'react';
import CampaignAssetsTable from './CampaignAssetsTable'

class CampaignAssets extends Component{
  render() {
    const { params } = this.props.match
    return (
        <CampaignAssetsTable campaignId={params.campaignId} />
    );
  }
}

export default CampaignAssets;
