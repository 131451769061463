import React, { Component } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
const util = require('util');

class StatusTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: 'PM', field: 'piddlleadgencampaign_projectmanager_id', lookup: { 0: 'Unassigned', 1: 'Hannah', 2: 'Reagan', 3: 'Rebecca', 4: 'Beth', 5: 'Brittany', 6: 'Catherine'}, editable: 'onUpdate' },
        { title: 'Due Date', field: 'piddlleadgencampaign_duedate', grouping: false, filtering: false, type: 'date', editable: 'onUpdate' },
        { title: 'ID', field: 'piddlleadgencampaign_id', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc' },
        { title: 'Publication', field: 'piddlleadgencampaign_publication', editable: 'never'},
        { title: 'Title', field: 'piddlleadgencampaign_campaignname', grouping: false, filtering: false, editable: 'never' },
        { title: 'Qualified', field: 'qualified regs', grouping: false, filtering: false, editable: 'never'  },
        { title: 'Goal', field: 'piddlleadgencampaign_goal', grouping: false, filtering: false, type: 'numeric', editable: 'onUpdate' },
        { title: '% of Goal', field: 'pct_of_goal', grouping: false, filtering: false, editable: 'never' },
        { title: 'Total', field: 'total regs', grouping: false, filtering: false, editable: 'never' }
      ],
      data: [
        {},
      ],
    }
  }

  componentDidMount() {

    axios.get(process.env.REACT_APP_SERVER_BASE_URL+'/leads/campaigns',{withCredentials: true})
      .then(response => {
        this.prepareData(response.data);
        this.setState({ data: response.data });
      })      
      .catch(error => {
        if (error.response && error.response.status == 403) {
          localStorage.removeItem('user');
          window.location.reload();
        }
      });

  }

  prepareData(data) {
    for (var i = 0; i < data.length; i++) {
      if(!data[i]['piddlleadgencampaign_projectmanager_id']) { data[i]['piddlleadgencampaign_projectmanager_id'] = 0 }
      if(!data[i]['qualified regs']) { data[i]['qualified regs'] = 0 }
      if (data[i]['piddlleadgencampaign_goal'] > 0) {
        data[i]['pct_of_goal'] = ((data[i]['qualified regs'] / data[i]['piddlleadgencampaign_goal']) * 100).toFixed(2);
      }
    }
  }

  render() {
    return (
      <MaterialTable
        title="Campaigns"
        columns={this.state.columns}
        data={this.state.data}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                {
                  axios.patch(process.env.REACT_APP_SERVER_BASE_URL+'/leads/campaign/update/'+newData.piddlleadgencampaign_id,newData,{withCredentials: true})
                  .then();                  
                  const data = this.state.data;
                  const index = data.indexOf(oldData);
                  data[index] = newData;
                  this.prepareData(data);
                  this.setState({ data }, () => resolve());
                }
                resolve()
              }, 1000)
            }),
        }}              
        options={{   
          rowStyle: rowData => {
            if(rowData['pct_of_goal'] < 25) {
              return {backgroundColor: 'pink'};
            }            
            else if(rowData['pct_of_goal'] < 100) {
              return {backgroundColor: 'lightyellow'};
            }
            else if(rowData['pct_of_goal'] >= 100) {
              return {backgroundColor: 'lightgreen'};
            }            
          },
          grouping: true, 
          filtering: true,
          pageSize: 15,
          pageSizeOptions: [10, 15, 20]          
        }}
        actions={[
          {
            icon: 'launch',
            tooltip: 'Campaign Leads',
            onClick: (event, rowData) => {
              window.location.href = '/leads/campaign/'+rowData['piddlleadgencampaign_id'];
            }
          },
          {
            icon: 'dynamic_feed',
            tooltip: 'Campaign Assets',
            onClick: (event, rowData) => {
              window.location.href = '/leads/campaign/'+rowData['piddlleadgencampaign_id']+'/assets';
            }
          }          
        ]}                      
      />
    )
  }
}
export default StatusTable;