import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
  withRouter
} from 'react-router-dom'
import { PrivateRoute } from './components/PrivateRoute';
import Leads from './components/Leads';
import AssetLeads from './components/AssetLeads';
import Campaigns from './components/Campaigns';
import CampaignAssets from './components/CampaignAssets';
import { LoginPage } from './LoginPage';
import NavBar from './NavBar';

class App extends React.Component {
    render() {
        return (
          <Router>
            <div className="container">
                <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
                <NavBar />
                <Route exact path="/login" component={LoginPage} />
                <PrivateRoute exact path="/" component={Campaigns} />
                <PrivateRoute exact path="/leads/campaign/:campaignId" component={Leads} />
                <PrivateRoute exact path="/leads/campaign/:campaignId/assets" component={CampaignAssets} />
                <PrivateRoute exact path="/leads/campaign/:campaignId/asset/:assetId" component={AssetLeads} />
            </div>
          </Router>
        );
    }
}

export default App;