import React, { Component } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
const util = require('util');

class CampaignAssetsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: 'Tile', field: 'title',  grouping: false, filtering: true},
        { title: 'Number of Leads', field: 'total', grouping: false, filtering: false, type: 'numeric'},
      ],
      data: [
        {},
      ],
    }
  }

  componentDidMount() {
    axios.get(process.env.REACT_APP_SERVER_BASE_URL+'/leads/campaign/'+this.props.campaignId+'/assets',{withCredentials: true})
      .then(response => {
        this.setState({ data: response.data });
      })
      .catch(error => {
        if (error && error.response.status == 403) {
          localStorage.removeItem('user');
          window.location.reload();
        }
      });
  }

  render() {
    return (
      <MaterialTable
        title="Assets"
        columns={this.state.columns}
        data={this.state.data}
        editable={{
        }}              
        options={{   
          grouping: true, 
          filtering: true,
          pageSize: 15,
          pageSizeOptions: [10, 15, 20],
          exportAllData: true,
          exportButton: true
        }}
        actions={[
          {
            icon: 'launch',
            tooltip: 'Asset Leads',
            onClick: (event, rowData) => {
              window.location.href = '/leads/campaign/'+this.props.campaignId+'/asset/'+rowData['assetId'];
            }
          }        
        ]}                        
      />
    )
  }
}
export default CampaignAssetsTable;