import React, { Component } from 'react';
import CampaignsTable from './CampaignsTable'

class Campaigns extends Component{
  render() {
    return (
        <CampaignsTable />
    );
  }
}

export default Campaigns;
