import axios from 'axios';

function login(username, password) {
	return axios.get(process.env.REACT_APP_SERVER_BASE_URL+'/auth/authenticate', { auth: { username, password },withCredentials: true })
        .then(res => {
            // login successful if there's a user in the response
            if (res.data.user !== undefined) {
                localStorage.setItem('user', res.data.user);
            }
    	})
    	.catch( error => {
    		console.log(error);
    	});
}

function logout() {
    //todo: call logout service
    localStorage.removeItem('user');
}


export const userService = {
    login,
    logout
};


