import React, { Component } from 'react';
import MaterialTable from 'material-table';
import axios from 'axios';
import Moment from 'moment';
const util = require('util');

class AssetLeadsTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [
        { title: 'Qualified', field: 'qualifiedstatus', lookup: { 'TBD': 'TBD', 'PREREJECTED': 'pre-rejected', 'REJECTED': 'rejected', 'PREACCEPTED': 'pre-accepted', 'ACCEPTED':'accepted'}, grouping: true, filtering: true},
        { title: 'Reg Date', field: 'regdate', grouping: false, filtering: false, type: 'datetime', editable: 'onUpdate'},
        { title: 'OptIn', field: 'standardLeadOptin', grouping: true, filtering: true, editable: 'never', defaultSort: 'desc', type: 'boolean'},         
        { title: 'ID', field: 'id', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc', hidden: true },
        { title: 'USER ID', field: 'piddluserid', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc', hidden: true },
        { title: 'Email', field: 'email', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc' },
        { title: 'First Name', field: 'firstname', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc' },
        { title: 'Last Name', field: 'lastname', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc' },
        { title: 'Company', field: 'company', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc' },
        { title: 'Job Title', field: 'title', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc' },
        { title: 'Address', field: 'address', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Address 2', field: 'addresstwo', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Zip', field: 'postalcode', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'City', field: 'city', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc' },
        { title: 'State', field: 'state', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Country', field: 'country', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc' },
        { title: 'Phone', field: 'phone', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Ext', field: 'ext', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Fax', field: 'fax', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Business', field: 'demogbusiness', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc' },
        { title: 'Business Other', field: 'demogbusinessother', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Revenue', field: 'demogcompanyrevenue', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Function', field: 'demogjobfunction', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc' },
        { title: 'Function Other', field: 'demogjobfunctionother', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Purchase Power', field: 'demogpurchasepower', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Purchase Power Budget', field: 'demogpurchasepowerbudget', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Network Interest', field: 'demognetworkinterest', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Tech Interest', field: 'demogtechinterest', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Tech Interest Other', field: 'demogtechinterestother', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Size', field: 'demogemployeecount', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc',hidden: true },
        { title: 'Tracking Code', field: 'trackingcode', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc'},
        { title: 'Custom 1 Varchar', field: 'customonevarchar', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc', hidden: true },
        { title: 'Custom 2 Varchar', field: 'customtwovarchar', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc', hidden: true },
        { title: 'Custom 3 Varchar', field: 'customthreevarchar', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc', hidden: true },
        { title: 'Custom 4 Varchar', field: 'customfourvarchar', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc', hidden: true },
        { title: 'Custom 5 Varchar', field: 'customfivevarchar', grouping: false, filtering: false, editable: 'never', defaultSort: 'desc', hidden: true },
      ],
      data: [
        {},
      ],
    }
  }

  componentDidMount() {
    axios.get(process.env.REACT_APP_SERVER_BASE_URL+'/leads/campaign/'+this.props.campaignId+'/asset/'+this.props.assetId,{withCredentials: true})
      .then(response => {
        this.prepareData(response.data);
        console.log(util.inspect(response.data));
        this.setState({ data: response.data });
      })
      .catch(error => {
        if ((typeof error.response !== 'undefined') && error.response.status == 403) {
          localStorage.removeItem('user');
          window.location.reload();
        }
      });
  }

  prepareData(data) {
    for (var i = 0; i < data.length; i++) {
      if (data[i]['total regs'] > 0 && data[i]['piddlleadgencampaign_goal'] > 0) {
        data[i]['pct_of_goal'] = ((data[i]['total regs'] / data[i]['piddlleadgencampaign_goal']) * 100).toFixed(2);
      }
      if( !data[i]['qualifiedstatus'] ){
        data[i]['qualifiedstatus'] = 'TBD'
      }
      data[i]['regdate'] = Moment(data[i]['regdate']).format('MM/DD/YY')
    }
  }

  render() {
    return (
      <MaterialTable
        title="Leads"
        columns={this.state.columns}
        data={this.state.data}
        editable={{
        }}              
        options={{   
          rowStyle: rowData => {
            if( rowData['qualifiedstatus'] == 'PREREJECTED' || rowData['qualifiedstatus'] == 'REJECTED' ) {
              return {backgroundColor: 'pink'};
            }            
            else if(rowData['qualifiedstatus'] == 'PREACCEPTED' || rowData['qualifiedstatus'] == 'ACCEPTED' ) {
              return {backgroundColor: 'lightgreen'};
            }
            else{
              return {backgroundColor: 'lightyellow'};
            }
          },
          grouping: true, 
          filtering: true,
          pageSize: 15,
          pageSizeOptions: [10, 15, 20],
          exportAllData: true,
          exportButton: true,
          columnsButton: true
        }}
        actions={[
          {
            icon: 'check',
            tooltip: 'Accept Lead',
            onClick: (event, rowData) => {
              console.log(process.env.REACT_APP_SERVER_BASE_URL+'/leads/update/'+rowData.id);
              axios.patch(process.env.REACT_APP_SERVER_BASE_URL+'/leads/update/'+rowData.id,{qualifiedstatus: 'ACCEPTED'},{withCredentials: true})
              .then((response) => {
                console.log(response);
                rowData.qualifiedstatus = 'ACCEPTED';
                const data = this.state.data;
                const index = data.indexOf(rowData);
                data[index] = rowData;                
                this.prepareData(data);
                this.setState({ data });  
              })
              .catch(error => {
                console.log(error)
              });                 
            }
          },
          {
            icon: 'block',
            tooltip: 'Reject Lead',
            onClick: (event, rowData) => {
              axios.patch(process.env.REACT_APP_SERVER_BASE_URL+'/leads/update/'+rowData.id,{qualifiedstatus: 'REJECTED'},{withCredentials: true})
              .then(() => {
                rowData.qualifiedstatus = 'REJECTED';
                const data = this.state.data;
                const index = data.indexOf(rowData);
                data[index] = rowData;                
                this.prepareData(data);
                this.setState({ data });  
              }).catch(error => {
                console.log(error)
              });                 
            }
          }          
        ]}                      
      />
    )
  }
}
export default AssetLeadsTable;