import React, { Component } from 'react';
import AssetLeadsTable from './AssetLeadsTable'

class AssetLeads extends Component{
  render() {
  	const { params } = this.props.match
    return (
        <AssetLeadsTable campaignId={params.campaignId} assetId={params.assetId}/>
    );
  }
}

export default AssetLeads;